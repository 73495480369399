import OrderSummaryPaidSubscription from '../../OrderSummaryPaidSubscription';
import OrderSummaryFreeSubscription from '../../OrderSummaryFreeSubscription';

const getOrderSummary = (planCode) => {
    switch (planCode) {
        case "pva":
        case "pva-vhma":
        case "ahi-annual":
        case "txcva":
        case "cocva":
        case "gacva":
        case "micva":
        case "iacva":
        case "michigan-vma":
            return OrderSummaryFreeSubscription;
        default: 
            return OrderSummaryPaidSubscription;
    }
};

const orderSummaryFactory = {
    getOrderSummary,
};

export default orderSummaryFactory;
