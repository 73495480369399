const getNullObjectPlan = () => {
    return {
        amount: 0,
        name: "",
        isDisplayQuantity: false,
        quantity: 1,
        isCouponApplicable: true,
        successUrl: "",
        cancelUrl: "",
        coreAddOns: [],
        addOns: [],
    };
}

const getPlan = (planCode, planAttributes) => {
    const freePlanCoreAddons = [
        "professional_development",
        "clinical_courses",
        "management_courses",
    ];

    const freeTXCVAPlanCoreAddons = [
        "TVMA-Level-1",
        "TVMA Level 2",
        "TVMA-Level-3",
    ];

    const freeCOCVAPlanCoreAddons = [
        "Colorado CVA Level 1",
        "Colorado CVA Level 2",
        "Colorado CVA Level 3",
    ];

    const freeGACVAPlanCoreAddons = [
        "Georgia_Level_1",
        "Georgia_Level_2",
        "Georgia_Level_3",
    ];

    const freeMICVAPlanCoreAddons = [
        "Missouri Level 1",
        "Missouri Level 2",
        "Missouri Level 3",
    ];

    const freeIACVAPlanCoreAddons = [
        "Iowa Level 1",
        "Iowa Level 2",
        "Iowa Level 3",
    ];

    const freeMichiganVMAPlanCoreAddons = [
        "Michigan_Level_1",
        "Michigan_Level_2",
        "Michigan_Level_3",
    ];

    const plan = {
        amount: planAttributes.amount,
        name: planAttributes.name,
        isDisplayQuantity: planAttributes.is_display_quantity,
        quantity: 1,
        isCouponApplicable: true,
        successUrl: planAttributes.success_url,
        cancelUrl: planAttributes.cancel_url,
    };

    switch (planCode) {
        case "pva":
        case "pva-vhma":
        case "ahi-annual":
            return {
                ...plan,
                coreAddOns: planAttributes.add_ons.filter((addOn) => freePlanCoreAddons.includes(addOn.code)),
                addOns: planAttributes.add_ons.filter((addOn) => !freePlanCoreAddons.includes(addOn.code)),
            };
        case "cacva":
        case "decva":
        case "flcva":
        case "ohcva":
            return {
                ...plan,
                isCouponApplicable: false,
                coreAddOns: [],
                addOns: planAttributes.add_ons,
            };
        case "txcva":
            return {
                ...plan,
                isCouponApplicable: true,
                coreAddOns: planAttributes.add_ons.filter((addOn) => freeTXCVAPlanCoreAddons.includes(addOn.code)).reverse(),
                addOns: planAttributes.add_ons.filter((addOn) => !freeTXCVAPlanCoreAddons.includes(addOn.code)),
            };
        case "cocva":
            return {
                ...plan,
                isCouponApplicable: true,
                coreAddOns: planAttributes.add_ons.filter((addOn) => freeCOCVAPlanCoreAddons.includes(addOn.code)).reverse(),
                addOns: planAttributes.add_ons.filter((addOn) => !freeCOCVAPlanCoreAddons.includes(addOn.code)),
            };
        case "gacva":
            return {
                ...plan,
                isCouponApplicable: true,
                coreAddOns: planAttributes.add_ons.filter((addOn) => freeGACVAPlanCoreAddons.includes(addOn.code)).reverse(),
                addOns: planAttributes.add_ons.filter((addOn) => !freeGACVAPlanCoreAddons.includes(addOn.code)),
            };
        case "micva":
            return {
                ...plan,
                isCouponApplicable: true,
                coreAddOns: planAttributes.add_ons.filter((addOn) => freeMICVAPlanCoreAddons.includes(addOn.code)).reverse(),
                addOns: planAttributes.add_ons.filter((addOn) => !freeMICVAPlanCoreAddons.includes(addOn.code)),
            };
        case "iacva":
            return {
                ...plan,
                isCouponApplicable: true,
                coreAddOns: planAttributes.add_ons.filter((addOn) => freeIACVAPlanCoreAddons.includes(addOn.code)).reverse(),
                addOns: planAttributes.add_ons.filter((addOn) => !freeIACVAPlanCoreAddons.includes(addOn.code)),
            };
        case "michigan-vma":
            return {
                ...plan,
                isCouponApplicable: true,
                coreAddOns: planAttributes.add_ons.filter((addOn) => freeMichiganVMAPlanCoreAddons.includes(addOn.code)).reverse(),
                addOns: planAttributes.add_ons.filter((addOn) => !freeMichiganVMAPlanCoreAddons.includes(addOn.code)),
            };
        default:
            return {
                ...plan,
                coreAddOns: [],
                addOns: planAttributes.add_ons,
            };

    }
}

const planFactory = {
    getNullObjectPlan,
    getPlan,
};

export default planFactory;